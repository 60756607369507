import { useQuery } from 'react-query'
import axiosInstance from '../helpers/axiosInstance'

export const useGetBalance = () => {
  return useQuery(
    'usersBalance',
    () =>
      axiosInstance({
        method: 'GET',
        url: `/user/balance`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.data
          }
        })
        .catch((error) => {
          throw error.response?.data?.error
        }),
    {
      // Refetch the data every 2.5 second
      refetchInterval: 2500,
      cacheTime: 0,
    }
  )
}
