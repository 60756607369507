import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  AppBar as MuiAppBar,
  CircularProgress,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  MenuRounded,
  AccountCircleRounded,
  PersonRounded,
  LogoutRounded,
  LockRounded,
  AccountBalanceWalletRounded,
} from "@mui/icons-material";
import Balance from "./subComponents/Balance";
import { useUserLogout } from "../../hooks/users/useUserLogout";
import AccessControl from "../../components/AccessControl";
import SelfPasswordUpdate from "../../components/SelfPasswordUpdate";
import { useGetProfileData } from "../../hooks/users/useGetProfileData";
import UserProfileDialog from "./UserProfileDialog";
import SelfTopUpDialog from "./SelfTopUpDialog";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const LayoutAppBar = ({ open, handleDrawerOpen }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  // Profile Dialog
  const userProfile = useGetProfileData();
  const [openProfileDialog, setOpenProfileDialog] = React.useState(false);
  const handleProfileDialogOpen = () => {
    userProfile.mutate();
    setOpenProfileDialog(true);
    handleClose();
  };
  const handleProfileDialogClose = () => setOpenProfileDialog(false);

  const { mutate, isLoading } = useUserLogout();

  // Self Topup Modal
  const [openSelfTopupDialog, setOpenSelfTopupDialog] = React.useState(false);
  const handleSelfTopupDialogOpen = () => {
    setOpenSelfTopupDialog(true);
    handleClose();
  };
  const handleSelfTopupDialogClose = () => setOpenSelfTopupDialog(false);

  // Self Password Update
  const [openSelfPasswordUpdate, setOpenSelfPasswordUpdate] =
    React.useState(false);
  const handleSelfPasswordUpdateOpen = () => {
    setOpenSelfPasswordUpdate(true);
    handleClose();
  };
  const handleSelfPasswordUpdateClose = () => setOpenSelfPasswordUpdate(false);

  return (
    <React.Fragment>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuRounded />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Demo Torrent Bill Checker
          </Typography>

          <Box sx={{ display: "flex" }} alignItems="center">
            <Balance />
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircleRounded />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted={false}
              variant="selectedMenu"
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleProfileDialogOpen}>
                <ListItemIcon>
                  <PersonRounded fontSize="small" />
                </ListItemIcon>
                <ListItemText>Profile</ListItemText>
              </MenuItem>

              <AccessControl>
                <MenuItem onClick={handleSelfTopupDialogOpen}>
                  <ListItemIcon>
                    <AccountBalanceWalletRounded fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Recharge Topup</ListItemText>
                </MenuItem>
              </AccessControl>

              <MenuItem onClick={handleSelfPasswordUpdateOpen}>
                <ListItemIcon>
                  <LockRounded fontSize="small" />
                </ListItemIcon>
                <ListItemText>Change password</ListItemText>
              </MenuItem>

              <MenuItem disabled={isLoading} onClick={() => mutate()}>
                <ListItemIcon>
                  {isLoading ? (
                    <CircularProgress size={18} />
                  ) : (
                    <LogoutRounded fontSize="small" />
                  )}
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <UserProfileDialog
        data={userProfile.data}
        isLoading={userProfile.isLoading}
        open={openProfileDialog}
        handleClose={handleProfileDialogClose}
      />
      <SelfTopUpDialog
        open={openSelfTopupDialog}
        handleClose={handleSelfTopupDialogClose}
      />
      <SelfPasswordUpdate
        open={openSelfPasswordUpdate}
        handleClose={handleSelfPasswordUpdateClose}
      />
    </React.Fragment>
  );
};

export default LayoutAppBar;
