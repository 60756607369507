import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  AccountBalanceRounded,
  AccountBalanceWalletRounded,
  AssignmentOutlined,
  ChevronLeftRounded,
  ContentPasteRounded,
  DashboardRounded,
  GroupRounded,
  WorkRounded,
  BoltRounded,
  PaidRounded,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";

const drawerWidth = 240;

export const LayoutDrawer = ({ open, handleDrawerClose }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const decoded = jwt_decode(localStorage.getItem("token"));

  const MENU = useMemo(
    () => [
      {
        title: "Dashboard",
        link: "/dashboard",
        isAdmin: "True",
        icon: <DashboardRounded />,
      },
      {
        title: "Torrent Power",
        link: "/torrentpower",
        isAdmin: "True",
        icon: <BoltRounded />,
      },
      {
        title: "Refund Request",
        link: "/refundrequest",
        isAdmin: "True",
        icon: <PaidRounded />,
      },
      {
        title: "Users",
        link: "/users",
        isAdmin: decoded?.admin,
        icon: <GroupRounded />,
      },
      {
        title: "Balance Ledger",
        link: "/balanceledger",
        isAdmin: "True",
        icon: <AccountBalanceWalletRounded />,
      },
      {
        title: "Plans",
        link: "/plans",
        isAdmin: "True",
        icon: <AssignmentOutlined />,
      },
      {
        title: "Topup Request",
        link: "/topup/request",
        isAdmin: "True",
        icon: <ContentPasteRounded />,
      },
      {
        title: "Jobs",
        link: "/jobs",
        isAdmin: "True",
        icon: <WorkRounded />,
      },
      {
        title: "Banks",
        link: "/banks",
        isAdmin: decoded?.admin,
        icon: <AccountBalanceRounded />,
      },
    ],
    [decoded]
  );

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <img
          src={`${process.env.PUBLIC_URL}/images/logo.png`}
          alt="Demo Torrent Bill Checker"
          height={45}
        />
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftRounded />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List disablePadding>
        {MENU.map((menu, index) => {
          return (
            menu.isAdmin === "True" && (
              <ListItem
                divider
                disablePadding
                onClick={() => navigate(menu.link)}
                key={index}
              >
                <ListItemButton
                  selected={location.pathname === menu.link ? true : false}
                >
                  <ListItemIcon
                    sx={{
                      color:
                        location.pathname === menu.link
                          ? "#1e659a"
                          : "#c5c5c5cc",
                    }}
                  >
                    {menu?.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={menu.title}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )
          );
        })}
      </List>
    </Drawer>
  );
};

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));
