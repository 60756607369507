import toast from 'react-hot-toast'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import axiosInstance from '../../helpers/axiosInstance'

export const useUserLogout = () => {
  const navigate = useNavigate()

  return useMutation(
    (values) => {
      return axiosInstance({
        method: 'GET',
        url: '/user/logout',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((res) => {
          return res.data
        })
        .catch((error) => {
          throw error
        })
    },
    {
      onSuccess: (data) => {
        localStorage.clear()
        navigate('/')
      },
      onError: (error, _newPost, rollback) => {
        toast.error(error.response.data.error)
        if (rollback) rollback()
      },
    }
  )
}
