import { useMutation } from "react-query";
import axiosInstance from "../../helpers/axiosInstance";

export const useGetProfileData = () => {
  return useMutation((values) =>
    axiosInstance({
      method: "GET",
      url: `user`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
      .catch((error) => {
        throw error.response.data.error;
      })
  );
};
