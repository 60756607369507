import React from 'react'
import { Typography, CircularProgress } from '@mui/material'
import CountUp from 'react-countup'
import { useGetBalance } from '../../../hooks/useGetBalance'

const Balance = () => {
  const { data, isLoading, error } = useGetBalance()

  return isLoading ? (
    <CircularProgress
      size={20}
      thickness={6}
      sx={{ flexShrink: 0 }}
      color="secondary"
    />
  ) : error ? (
    <Typography variant="body1" noWrap component="div">
      {error}
    </Typography>
  ) : (
    <Typography variant="body2" noWrap component="div">
      Balance:&nbsp;
      <CountUp useEasing={true} duration={2} start={0} end={data?.balance} />
    </Typography>
  )
}

export default Balance
