import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import LayoutAppBar from './components/LayoutAppBar'
import { LayoutDrawer, DrawerHeader } from './components/LayoutDrawer'

const drawerWidth = 240

const Layout = () => {
  const [open, setOpen] = React.useState(false)

  const location = useLocation()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return localStorage.getItem('token') ? (
    <Box sx={{ display: 'flex' }}>
      <LayoutAppBar open={open} handleDrawerOpen={handleDrawerOpen} />
      <LayoutDrawer open={open} handleDrawerClose={handleDrawerClose} />
      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  )
}

export default Layout

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.grey[100],
    overflow: 'hidden',
    overflowY: 'auto',
    height: '100vh',
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
)
