import axios from "axios"

const TOKEN = localStorage.getItem("token")

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  crossdomain: true,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token") ? TOKEN : ""}`,
  },
})

const errorHandler = (error) => {
  // console.log(error.response)

  if (error.response.status === 401 || error.response.status === 403) {
    localStorage.clear()
    window.location = "/"
  }
  return Promise.reject(error)
}

const requestHandler = (request) => {
  return request
}

const responseHandler = (response) => {
  return response
}

axiosInstance.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
)
axiosInstance.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
)

export default axiosInstance
