import { forwardRef, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  DialogContentText,
} from '@mui/material'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton'
import toast from 'react-hot-toast'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import axiosInstance from '../helpers/axiosInstance'
import InputField from '../components/InputField'

const SelfPasswordUpdate = ({ open, handleClose }) => {
  const [passwordToggle1, setPasswordToggle1] = useState(false)
  const [passwordToggle2, setPasswordToggle2] = useState(false)

  const initialValues = {
    oldpassword: '',
    newpassword: '',
  }
  const validationSchema = Yup.object({
    oldpassword: Yup.string().min(3).max(20).required().label('Old Password'),
    newpassword: Yup.string().min(3).max(20).required().label('New Password'),
  })

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      keepMounted={false}
      TransitionComponent={Transition}
      disableEscapeKeyDown
      onClose={handleClose}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          await axiosInstance({
            method: 'PUT',
            url: '/user/password',
            data: {
              oldpassword: values.oldpassword,
              newpassword: values.newpassword,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          })
            .then((response) => {
              toast.success('Password updated successfully')
              actions.resetForm()
              handleClose()
            })
            .catch((error) => {
              toast.error(error.response.data.error)
            })
        }}
      >
        {({ dirty, isValid, isSubmitting }) => (
          <Form autoComplete="off">
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent dividers>
              <Stack spacing={2}>
                <DialogContentText>
                  Once Password is changed you would be logged out.
                </DialogContentText>
                <InputField
                  fullWidth
                  type={passwordToggle1 ? 'text' : 'password'}
                  id="oldpassword"
                  label="Old Password"
                  name="oldpassword"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setPasswordToggle1(!passwordToggle1)}
                          aria-label="toggle password visibility"
                        >
                          {passwordToggle1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <InputField
                  fullWidth
                  type={passwordToggle2 ? 'text' : 'password'}
                  id="newpassword"
                  label="New Password"
                  name="newpassword"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setPasswordToggle2(!passwordToggle2)}
                          aria-label="toggle password visibility"
                        >
                          {passwordToggle2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button size="small" onClick={handleClose}>
                Close
              </Button>
              <LoadingButton
                type="submit"
                size="small"
                loading={isSubmitting}
                disabled={!(dirty && isValid) || isSubmitting}
              >
                Update password
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default SelfPasswordUpdate

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
